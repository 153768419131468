.rio-banner {
    display: flex;
    align-items: center;
    position: relative;
    height: 36px;
    color: var(--color_text_info);
    font-weight: 500;
    font-size: 14px;
}

.rio-banner--variant_info {
    background: var(--color_background_info_whisper);
}

.rio-banner--variant_error {
    background: var(--color_background_danger);
    color: var(--color_text_danger_inverse);
}

/* don't accidentally affect subsequent banners */
.rio-banner--has_details-open ~ .rio-banner {
    pointer-events: none;
}

.rio-banner-title {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    flex: 1 1 auto;
    margin-inline: 8px;
}

.rio-banner-title__icon--dropdown {
    margin-inline-start: 2px;
}

.rio-banner-title__details-toggle {
    font-weight: 700;
    line-height: 1.5;
}

.rio-banner__aside {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    margin-inline-start: auto;
    flex: 0 0 auto;
    gap: 4px;
}

.rio-banner-pagination {
    display: flex;
    align-items: center;
    height: 100%;
    padding-inline: 4px;
    border-style: solid;
    border-width: 0;
    border-inline-start: 1px solid var(--color_border_neutral);
    user-select: none;
    cursor: default;
}

.rio-banner-pagination__text {
    padding-inline: 2px;
}

.rio-banner-pagination__number {
    display: inline-flex;
    justify-content: center;
    min-width: 10px;
}

.rio-banner-dropdown {
    border-radius: 8px;
}

.rio-banner-dropdown__inner-content {
    color: var(--color_text_neutral_loud);
}

.rio-banner-dropdown__content {
    max-width: 600px;
    padding: 12px;
}

.rio-banner-dropdown__title {
    font-weight: 700;
}

.rio-banner-dropdown__body {
    font-weight: 500;
    line-height: 1.5;
}
