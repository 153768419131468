@font-face {
	font-family: "aa-icons";
	font-display: block;
	src: url("/asset/aa-icons-f77cf2f9be12f1d912f4.woff2") format("woff2"),
url("/asset/aa-icons-86e6de2907546726f9ca.ttf") format("truetype");
}

.aa-icon {
	line-height: 1;
}

.aa-icon:before {
	font-family: aa-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.aa-icon-action-align-columns:before {
	content: "\f101";
}
.aa-icon-action-align-rows:before {
	content: "\f102";
}
.aa-icon-action-analyze:before {
	content: "\f103";
}
.aa-icon-action-arrow-down:before {
	content: "\f104";
}
.aa-icon-action-arrow-left:before {
	content: "\f105";
}
.aa-icon-action-arrow-right:before {
	content: "\f106";
}
.aa-icon-action-arrow-up:before {
	content: "\f107";
}
.aa-icon-action-botstore-add:before {
	content: "\f108";
}
.aa-icon-action-botstore-submit:before {
	content: "\f109";
}
.aa-icon-action-botstore:before {
	content: "\f10a";
}
.aa-icon-action-cancel:before {
	content: "\f10b";
}
.aa-icon-action-checkin:before {
	content: "\f10c";
}
.aa-icon-action-checkout-cancel:before {
	content: "\f10d";
}
.aa-icon-action-checkout:before {
	content: "\f10e";
}
.aa-icon-action-chevron-down:before {
	content: "\f10f";
}
.aa-icon-action-chevron-left:before {
	content: "\f110";
}
.aa-icon-action-chevron-right:before {
	content: "\f111";
}
.aa-icon-action-chevron-up:before {
	content: "\f112";
}
.aa-icon-action-clipboard-copy--shared:before {
	content: "\f113";
}
.aa-icon-action-clipboard-copy:before {
	content: "\f114";
}
.aa-icon-action-clipboard-cut:before {
	content: "\f115";
}
.aa-icon-action-clipboard-paste--shared:before {
	content: "\f116";
}
.aa-icon-action-clipboard-paste:before {
	content: "\f117";
}
.aa-icon-action-clone:before {
	content: "\f118";
}
.aa-icon-action-close:before {
	content: "\f119";
}
.aa-icon-action-code-analysis:before {
	content: "\f11a";
}
.aa-icon-action-compare:before {
	content: "\f11b";
}
.aa-icon-action-copy:before {
	content: "\f11c";
}
.aa-icon-action-create:before {
	content: "\f11d";
}
.aa-icon-action-customize-table:before {
	content: "\f11e";
}
.aa-icon-action-dashboard-export:before {
	content: "\f11f";
}
.aa-icon-action-dashboard-filter:before {
	content: "\f120";
}
.aa-icon-action-dashboard-fullscreen:before {
	content: "\f121";
}
.aa-icon-action-dashboard-style:before {
	content: "\f122";
}
.aa-icon-action-delete:before {
	content: "\f123";
}
.aa-icon-action-device:before {
	content: "\f124";
}
.aa-icon-action-download:before {
	content: "\f125";
}
.aa-icon-action-draw-region:before {
	content: "\f126";
}
.aa-icon-action-edit:before {
	content: "\f127";
}
.aa-icon-action-email:before {
	content: "\f128";
}
.aa-icon-action-entry-archive:before {
	content: "\f129";
}
.aa-icon-action-entry-restore:before {
	content: "\f12a";
}
.aa-icon-action-export-details:before {
	content: "\f12b";
}
.aa-icon-action-export-selected-item:before {
	content: "\f12c";
}
.aa-icon-action-export:before {
	content: "\f12d";
}
.aa-icon-action-favorite--not-selected:before {
	content: "\f12e";
}
.aa-icon-action-favorite--selected:before {
	content: "\f12f";
}
.aa-icon-action-file-dependency:before {
	content: "\f130";
}
.aa-icon-action-file-pdf:before {
	content: "\f131";
}
.aa-icon-action-file-word:before {
	content: "\f132";
}
.aa-icon-action-find--fill:before {
	content: "\f133";
}
.aa-icon-action-find:before {
	content: "\f134";
}
.aa-icon-action-git-restore:before {
	content: "\f135";
}
.aa-icon-action-group:before {
	content: "\f136";
}
.aa-icon-action-import:before {
	content: "\f137";
}
.aa-icon-action-install-license:before {
	content: "\f138";
}
.aa-icon-action-label:before {
	content: "\f139";
}
.aa-icon-action-link-external:before {
	content: "\f13a";
}
.aa-icon-action-link-internal:before {
	content: "\f13b";
}
.aa-icon-action-list-issues:before {
	content: "\f13c";
}
.aa-icon-action-list-replace:before {
	content: "\f13d";
}
.aa-icon-action-list-search:before {
	content: "\f13e";
}
.aa-icon-action-lock--unlocked:before {
	content: "\f13f";
}
.aa-icon-action-minimize:before {
	content: "\f140";
}
.aa-icon-action-move-bottom:before {
	content: "\f141";
}
.aa-icon-action-move-top:before {
	content: "\f142";
}
.aa-icon-action-pause:before {
	content: "\f143";
}
.aa-icon-action-pin:before {
	content: "\f144";
}
.aa-icon-action-pinned-clear:before {
	content: "\f145";
}
.aa-icon-action-pinned:before {
	content: "\f146";
}
.aa-icon-action-preview--hidden:before {
	content: "\f147";
}
.aa-icon-action-preview--visible:before {
	content: "\f148";
}
.aa-icon-action-reading-pane-hide:before {
	content: "\f149";
}
.aa-icon-action-reading-pane-show:before {
	content: "\f14a";
}
.aa-icon-action-record--fill:before {
	content: "\f14b";
}
.aa-icon-action-record-aisense:before {
	content: "\f14c";
}
.aa-icon-action-record:before {
	content: "\f14d";
}
.aa-icon-action-recover:before {
	content: "\f14e";
}
.aa-icon-action-redo:before {
	content: "\f14f";
}
.aa-icon-action-refresh:before {
	content: "\f150";
}
.aa-icon-action-reload:before {
	content: "\f151";
}
.aa-icon-action-remove:before {
	content: "\f152";
}
.aa-icon-action-resume:before {
	content: "\f153";
}
.aa-icon-action-run--local:before {
	content: "\f154";
}
.aa-icon-action-run:before {
	content: "\f155";
}
.aa-icon-action-schedule-bot:before {
	content: "\f156";
}
.aa-icon-action-swap:before {
	content: "\f157";
}
.aa-icon-action-toggle--disabled:before {
	content: "\f158";
}
.aa-icon-action-toggle--enabled:before {
	content: "\f159";
}
.aa-icon-action-undo:before {
	content: "\f15a";
}
.aa-icon-action-ungroup:before {
	content: "\f15b";
}
.aa-icon-action-update-to-default-version:before {
	content: "\f15c";
}
.aa-icon-action-update:before {
	content: "\f15d";
}
.aa-icon-action-upgrade--circle:before {
	content: "\f15e";
}
.aa-icon-action-upload:before {
	content: "\f15f";
}
.aa-icon-action-versions:before {
	content: "\f160";
}
.aa-icon-action-view-info--hidden:before {
	content: "\f161";
}
.aa-icon-action-view-info--visible:before {
	content: "\f162";
}
.aa-icon-action-view:before {
	content: "\f163";
}
.aa-icon-action-workload-mark-complete:before {
	content: "\f164";
}
.aa-icon-action-workload-mark-ready-to-run:before {
	content: "\f165";
}
.aa-icon-action-zoom-fit:before {
	content: "\f166";
}
.aa-icon-action-zoom-in:before {
	content: "\f167";
}
.aa-icon-action-zoom-out:before {
	content: "\f168";
}
.aa-icon-activity--default:before {
	content: "\f169";
}
.aa-icon-activity--paused:before {
	content: "\f16a";
}
.aa-icon-activity--scheduled-recurring:before {
	content: "\f16b";
}
.aa-icon-activity--scheduled:before {
	content: "\f16c";
}
.aa-icon-activity-navigation:before {
	content: "\f16d";
}
.aa-icon-administration-navigation:before {
	content: "\f16e";
}
.aa-icon-application--calculator:before {
	content: "\f16f";
}
.aa-icon-application--chrome:before {
	content: "\f170";
}
.aa-icon-application--citrix:before {
	content: "\f171";
}
.aa-icon-application--default:before {
	content: "\f172";
}
.aa-icon-application--edge:before {
	content: "\f173";
}
.aa-icon-application--excel:before {
	content: "\f174";
}
.aa-icon-application--firefox:before {
	content: "\f175";
}
.aa-icon-application--internet-explorer:before {
	content: "\f176";
}
.aa-icon-application--nodepad-plus-plus:before {
	content: "\f177";
}
.aa-icon-application--notepad:before {
	content: "\f178";
}
.aa-icon-application--oracle:before {
	content: "\f179";
}
.aa-icon-application--outlook:before {
	content: "\f17a";
}
.aa-icon-application--paint:before {
	content: "\f17b";
}
.aa-icon-application--pdf:before {
	content: "\f17c";
}
.aa-icon-application--postman:before {
	content: "\f17d";
}
.aa-icon-application--powerpoint:before {
	content: "\f17e";
}
.aa-icon-application--safari:before {
	content: "\f17f";
}
.aa-icon-application--salesforce:before {
	content: "\f180";
}
.aa-icon-application--sap:before {
	content: "\f181";
}
.aa-icon-application--service-now:before {
	content: "\f182";
}
.aa-icon-application--teams:before {
	content: "\f183";
}
.aa-icon-application--windows:before {
	content: "\f184";
}
.aa-icon-application--word:before {
	content: "\f185";
}
.aa-icon-application--workday:before {
	content: "\f186";
}
.aa-icon-application--zendesk:before {
	content: "\f187";
}
.aa-icon-application--zoom:before {
	content: "\f188";
}
.aa-icon-approval--default:before {
	content: "\f189";
}
.aa-icon-audit-log--default:before {
	content: "\f18a";
}
.aa-icon-audit-log-navigation:before {
	content: "\f18b";
}
.aa-icon-bot--atmx:before {
	content: "\f18c";
}
.aa-icon-bot--default:before {
	content: "\f18d";
}
.aa-icon-bot--headless:before {
	content: "\f18e";
}
.aa-icon-bot--iq-bot:before {
	content: "\f18f";
}
.aa-icon-bot--metabot:before {
	content: "\f190";
}
.aa-icon-bot--workflow:before {
	content: "\f191";
}
.aa-icon-bot-create--headless:before {
	content: "\f192";
}
.aa-icon-bot-create:before {
	content: "\f193";
}
.aa-icon-bot-navigation:before {
	content: "\f194";
}
.aa-icon-botinsight-navigation:before {
	content: "\f195";
}
.aa-icon-botstore-navigation:before {
	content: "\f196";
}
.aa-icon-business-process--default:before {
	content: "\f197";
}
.aa-icon-business-process-navigation:before {
	content: "\f198";
}
.aa-icon-command--default:before {
	content: "\f199";
}
.aa-icon-credential--default:before {
	content: "\f19a";
}
.aa-icon-credential--standard:before {
	content: "\f19b";
}
.aa-icon-credential--user-created:before {
	content: "\f19c";
}
.aa-icon-credential-create:before {
	content: "\f19d";
}
.aa-icon-dashboard--default:before {
	content: "\f19e";
}
.aa-icon-dashboard--system-created:before {
	content: "\f19f";
}
.aa-icon-dashboard--user-created:before {
	content: "\f1a0";
}
.aa-icon-dashboard-navigation:before {
	content: "\f1a1";
}
.aa-icon-device--attended:before {
	content: "\f1a2";
}
.aa-icon-device--creator:before {
	content: "\f1a3";
}
.aa-icon-device--default:before {
	content: "\f1a4";
}
.aa-icon-device--favorite:before {
	content: "\f1a5";
}
.aa-icon-device--image:before {
	content: "\f1a6";
}
.aa-icon-device--local-favorite:before {
	content: "\f1a7";
}
.aa-icon-device--local:before {
	content: "\f1a8";
}
.aa-icon-device--pool-headless:before {
	content: "\f1a9";
}
.aa-icon-device--pool:before {
	content: "\f1aa";
}
.aa-icon-device--runner:before {
	content: "\f1ab";
}
.aa-icon-device--selected:before {
	content: "\f1ac";
}
.aa-icon-device-create--pool-headless:before {
	content: "\f1ad";
}
.aa-icon-device-create--pool:before {
	content: "\f1ae";
}
.aa-icon-device-create:before {
	content: "\f1af";
}
.aa-icon-device-navigation:before {
	content: "\f1b0";
}
.aa-icon-discovery-bot-navigation:before {
	content: "\f1b1";
}
.aa-icon-file--default:before {
	content: "\f1b2";
}
.aa-icon-file--document:before {
	content: "\f1b3";
}
.aa-icon-file--executable:before {
	content: "\f1b4";
}
.aa-icon-file--image:before {
	content: "\f1b5";
}
.aa-icon-file--report:before {
	content: "\f1b6";
}
.aa-icon-file--script:before {
	content: "\f1b7";
}
.aa-icon-file--text:before {
	content: "\f1b8";
}
.aa-icon-file--workflow:before {
	content: "\f1b9";
}
.aa-icon-folder--closed:before {
	content: "\f1ba";
}
.aa-icon-folder--default:before {
	content: "\f1bb";
}
.aa-icon-folder--move-into:before {
	content: "\f1bc";
}
.aa-icon-folder--open:before {
	content: "\f1bd";
}
.aa-icon-folder-create:before {
	content: "\f1be";
}
.aa-icon-folder-selected--closed:before {
	content: "\f1bf";
}
.aa-icon-folder-selected--open:before {
	content: "\f1c0";
}
.aa-icon-form--default:before {
	content: "\f1c1";
}
.aa-icon-form-column:before {
	content: "\f1c2";
}
.aa-icon-form-create:before {
	content: "\f1c3";
}
.aa-icon-form-element--button:before {
	content: "\f1c4";
}
.aa-icon-form-element--checkbox:before {
	content: "\f1c5";
}
.aa-icon-form-element--curency:before {
	content: "\f1c6";
}
.aa-icon-form-element--date-range:before {
	content: "\f1c7";
}
.aa-icon-form-element--date:before {
	content: "\f1c8";
}
.aa-icon-form-element--document:before {
	content: "\f1c9";
}
.aa-icon-form-element--dropdown:before {
	content: "\f1ca";
}
.aa-icon-form-element--dynamic:before {
	content: "\f1cb";
}
.aa-icon-form-element--label:before {
	content: "\f1cc";
}
.aa-icon-form-element--number:before {
	content: "\f1cd";
}
.aa-icon-form-element--password:before {
	content: "\f1ce";
}
.aa-icon-form-element--radio:before {
	content: "\f1cf";
}
.aa-icon-form-element--rich-text:before {
	content: "\f1d0";
}
.aa-icon-form-element--text-area:before {
	content: "\f1d1";
}
.aa-icon-form-element--text:before {
	content: "\f1d2";
}
.aa-icon-form-element--toggle:before {
	content: "\f1d3";
}
.aa-icon-global-value--default:before {
	content: "\f1d4";
}
.aa-icon-global-value-create:before {
	content: "\f1d5";
}
.aa-icon-hbc-in-progress:before {
	content: "\f1d6";
}
.aa-icon-hbc-info:before {
	content: "\f1d7";
}
.aa-icon-hbc-pending:before {
	content: "\f1d8";
}
.aa-icon-hbc-request-navigation:before {
	content: "\f1d9";
}
.aa-icon-hbc-state--cancelled:before {
	content: "\f1da";
}
.aa-icon-hbc-state--completed:before {
	content: "\f1db";
}
.aa-icon-hbc-state--failed:before {
	content: "\f1dc";
}
.aa-icon-hbc-task-navigation:before {
	content: "\f1dd";
}
.aa-icon-hbc-task-validation:before {
	content: "\f1de";
}
.aa-icon-hbc-team:before {
	content: "\f1df";
}
.aa-icon-locker--default:before {
	content: "\f1e0";
}
.aa-icon-locker--standard:before {
	content: "\f1e1";
}
.aa-icon-locker--user-created:before {
	content: "\f1e2";
}
.aa-icon-locker-create:before {
	content: "\f1e3";
}
.aa-icon-migration--default:before {
	content: "\f1e4";
}
.aa-icon-migration-assistant:before {
	content: "\f1e5";
}
.aa-icon-migration-navigation:before {
	content: "\f1e6";
}
.aa-icon-misc-anchor:before {
	content: "\f1e7";
}
.aa-icon-misc-bot-dots--with-two-circling-arrows:before {
	content: "\f1e8";
}
.aa-icon-misc-breakpoint-clear:before {
	content: "\f1e9";
}
.aa-icon-misc-breakpoint:before {
	content: "\f1ea";
}
.aa-icon-misc-button-list-actions:before {
	content: "\f1eb";
}
.aa-icon-misc-caret--left:before {
	content: "\f1ec";
}
.aa-icon-misc-caret--right:before {
	content: "\f1ed";
}
.aa-icon-misc-caret-filled--left:before {
	content: "\f1ee";
}
.aa-icon-misc-caret-filled--right:before {
	content: "\f1ef";
}
.aa-icon-misc-checkmark:before {
	content: "\f1f0";
}
.aa-icon-misc-clear:before {
	content: "\f1f1";
}
.aa-icon-misc-editor-debug:before {
	content: "\f1f2";
}
.aa-icon-misc-editor-run:before {
	content: "\f1f3";
}
.aa-icon-misc-editor-trigger:before {
	content: "\f1f4";
}
.aa-icon-misc-elevated:before {
	content: "\f1f5";
}
.aa-icon-misc-exclamation-mark:before {
	content: "\f1f6";
}
.aa-icon-misc-generic-art:before {
	content: "\f1f7";
}
.aa-icon-misc-info:before {
	content: "\f1f8";
}
.aa-icon-misc-message-system-level-arrow:before {
	content: "\f1f9";
}
.aa-icon-misc-message-system-level-close:before {
	content: "\f1fa";
}
.aa-icon-misc-message-system-warning:before {
	content: "\f1fb";
}
.aa-icon-misc-missing:before {
	content: "\f1fc";
}
.aa-icon-misc-search-filter-dropdown-arrow:before {
	content: "\f1fd";
}
.aa-icon-misc-search-filter-dropdown-selected:before {
	content: "\f1fe";
}
.aa-icon-misc-search-filter-object-bar-cancel:before {
	content: "\f1ff";
}
.aa-icon-misc-search-filter-search:before {
	content: "\f200";
}
.aa-icon-misc-sort--ascending-1:before {
	content: "\f201";
}
.aa-icon-misc-sort--ascending-2:before {
	content: "\f202";
}
.aa-icon-misc-sort--ascending-3:before {
	content: "\f203";
}
.aa-icon-misc-sort--ascending:before {
	content: "\f204";
}
.aa-icon-misc-sort--descending-1:before {
	content: "\f205";
}
.aa-icon-misc-sort--descending-2:before {
	content: "\f206";
}
.aa-icon-misc-sort--descending-3:before {
	content: "\f207";
}
.aa-icon-misc-sort--descending:before {
	content: "\f208";
}
.aa-icon-misc-sort-ascending:before {
	content: "\f209";
}
.aa-icon-misc-sort-descending:before {
	content: "\f20a";
}
.aa-icon-misc-table-actions-menu:before {
	content: "\f20b";
}
.aa-icon-misc-tbd:before {
	content: "\f20c";
}
.aa-icon-misc-toast-close:before {
	content: "\f20d";
}
.aa-icon-misc-unsaved:before {
	content: "\f20e";
}
.aa-icon-package--default:before {
	content: "\f20f";
}
.aa-icon-package-create:before {
	content: "\f210";
}
.aa-icon-package-preload:before {
	content: "\f211";
}
.aa-icon-pathfinder-activity:before {
	content: "\f212";
}
.aa-icon-pathfinder-administration:before {
	content: "\f213";
}
.aa-icon-pathfinder-automation:before {
	content: "\f214";
}
.aa-icon-pathfinder-device:before {
	content: "\f215";
}
.aa-icon-pathfinder-discovery:before {
	content: "\f216";
}
.aa-icon-pathfinder-explore:before {
	content: "\f217";
}
.aa-icon-pathfinder-help:before {
	content: "\f218";
}
.aa-icon-pathfinder-home:before {
	content: "\f219";
}
.aa-icon-pathfinder-manage:before {
	content: "\f21a";
}
.aa-icon-pathfinder-processes:before {
	content: "\f21b";
}
.aa-icon-pathfinder-requests:before {
	content: "\f21c";
}
.aa-icon-pathfinder-tasks:before {
	content: "\f21d";
}
.aa-icon-policy--default:before {
	content: "\f21e";
}
.aa-icon-policy-assign:before {
	content: "\f21f";
}
.aa-icon-policy-inherit:before {
	content: "\f220";
}
.aa-icon-process--default:before {
	content: "\f221";
}
.aa-icon-process-break:before {
	content: "\f222";
}
.aa-icon-process-continue:before {
	content: "\f223";
}
.aa-icon-process-create:before {
	content: "\f224";
}
.aa-icon-process-failed:before {
	content: "\f225";
}
.aa-icon-process-filter:before {
	content: "\f226";
}
.aa-icon-process-goto:before {
	content: "\f227";
}
.aa-icon-process-launch:before {
	content: "\f228";
}
.aa-icon-profile-navigation:before {
	content: "\f229";
}
.aa-icon-queue--default:before {
	content: "\f22a";
}
.aa-icon-queue--not-in-use:before {
	content: "\f22b";
}
.aa-icon-queue-create:before {
	content: "\f22c";
}
.aa-icon-queue-template--default:before {
	content: "\f22d";
}
.aa-icon-role--default:before {
	content: "\f22e";
}
.aa-icon-role--system-created:before {
	content: "\f22f";
}
.aa-icon-role--user-created:before {
	content: "\f230";
}
.aa-icon-role-create:before {
	content: "\f231";
}
.aa-icon-template--default:before {
	content: "\f232";
}
.aa-icon-tray-alert:before {
	content: "\f233";
}
.aa-icon-tray-device:before {
	content: "\f234";
}
.aa-icon-tray-favorites--empty:before {
	content: "\f235";
}
.aa-icon-tray-favorites-hold-and-drag:before {
	content: "\f236";
}
.aa-icon-tray-favorites-unfavorite:before {
	content: "\f237";
}
.aa-icon-tray-favorites:before {
	content: "\f238";
}
.aa-icon-tray-help:before {
	content: "\f239";
}
.aa-icon-tray-language:before {
	content: "\f23a";
}
.aa-icon-tray-notifications-close:before {
	content: "\f23b";
}
.aa-icon-tray-notifications-empty:before {
	content: "\f23c";
}
.aa-icon-tray-notifications-go-to-arrow:before {
	content: "\f23d";
}
.aa-icon-tray-notifications:before {
	content: "\f23e";
}
.aa-icon-tray-profile:before {
	content: "\f23f";
}
.aa-icon-trigger--default:before {
	content: "\f240";
}
.aa-icon-trigger-create:before {
	content: "\f241";
}
.aa-icon-user--admin-disabled:before {
	content: "\f242";
}
.aa-icon-user--admin:before {
	content: "\f243";
}
.aa-icon-user--bot-creator-disabled:before {
	content: "\f244";
}
.aa-icon-user--bot-creator:before {
	content: "\f245";
}
.aa-icon-user--bot-runner-disabled:before {
	content: "\f246";
}
.aa-icon-user--bot-runner:before {
	content: "\f247";
}
.aa-icon-user--default-disabled:before {
	content: "\f248";
}
.aa-icon-user--default:before {
	content: "\f249";
}
.aa-icon-user--other-disabled:before {
	content: "\f24a";
}
.aa-icon-user--other:before {
	content: "\f24b";
}
.aa-icon-user-create:before {
	content: "\f24c";
}
.aa-icon-variable--any:before {
	content: "\f24d";
}
.aa-icon-variable--boolean:before {
	content: "\f24e";
}
.aa-icon-variable--credential:before {
	content: "\f24f";
}
.aa-icon-variable--datetime:before {
	content: "\f250";
}
.aa-icon-variable--default:before {
	content: "\f251";
}
.aa-icon-variable--dictionary:before {
	content: "\f252";
}
.aa-icon-variable--file:before {
	content: "\f253";
}
.aa-icon-variable--form:before {
	content: "\f254";
}
.aa-icon-variable--image:before {
	content: "\f255";
}
.aa-icon-variable--list:before {
	content: "\f256";
}
.aa-icon-variable--number:before {
	content: "\f257";
}
.aa-icon-variable--record:before {
	content: "\f258";
}
.aa-icon-variable--session:before {
	content: "\f259";
}
.aa-icon-variable--string:before {
	content: "\f25a";
}
.aa-icon-variable--table:before {
	content: "\f25b";
}
.aa-icon-variable--window:before {
	content: "\f25c";
}
.aa-icon-variable-create:before {
	content: "\f25d";
}
.aa-icon-variable-insert:before {
	content: "\f25e";
}
.aa-icon-work-item--default:before {
	content: "\f25f";
}
.aa-icon-work-item--on-hold:before {
	content: "\f260";
}
.aa-icon-work-item--ready-to-run:before {
	content: "\f261";
}
.aa-icon-workload-navigation:before {
	content: "\f262";
}
