@import "~@automationanywhere/rio-components/src/legacy";
@import "~@automationanywhere/rio-components/src/utility";

.licensedetails {

    .licensedetails-usage-details {
        margin-top: 30px;
    }

    .licensedetails-user-details {
        margin-top: 25px;
    }
}
