.firstlogincvsettingspage {
    .firstlogincvsettingspage-subheading {
        padding-bottom: 10px;
        font-weight: bold;
    }

    .firstlogincvsettingspage-copy-wrapper {
        margin-top: 14px;
    }

    .firstlogincvsettingspage-radio {
        margin-top: 1px;
        margin-bottom: 5px;
        font-weight: bold;
    }
}
