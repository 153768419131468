.globalvalueusergroupfields {
    position: relative;

    .globalvalueusergroupfields-textinput {
        width: calc(100% - 125px);
    }

    .globalvalueusergroupfields-button {
        position: absolute;
        top: 3px;
        right: 0;
    }
}