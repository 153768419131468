.rio-badge {
    display: flex;
    justify-content: center;
    flex: 0;
    align-items: center;
    width: max-content;
    border-radius: 1000px;
    min-width: 1em;
    height: 1em;
    flex: 0 0 auto;
    font-size: var(--rio-badge-size);
}

.rio-badge--is_filled {
    background-color: var(--rio-badge-circle-color);
}

.rio-badge--is_stroke {
    background-color: var(--color_background_neutral);
    border: max(1.5px, 0.0725em) solid var(--rio-badge-circle-color);
}

.rio-badge__content {
    display: flex;
    font-weight: 500;
    font-size: 0.75em;
}

.rio-badge__content {
    color: var(--rio-badge-content-color);
}

.rio-badge__content--with_icon-and-children {
    padding-inline: 0.125em 0.25em;
}

.rio-badge__children--no_icon {
    padding-inline: 0.175em;
}