.rio-mode-input {
    --rio-mode-input--background: var(--color_background_neutral_whisper);
    --rio-mode-input--wrap: wrap;
    --rio-mode-input__option--color: var(--color_text_neutral);
    --rio-mode-input__option--background: var(--color_background_transparent);
    --rio-mode-input__option--selected-background: var(--color_background_transparent);
    --rio-mode-input__option--cursor: default;
    --rio-mode-input--transition-speed: 300ms;
    --rio-mode-input--transition: none;
    --rio-mode-input__option-indicator--opacity: 0;
    --rio-mode-input__moving-indicator--opacity: 1;
}

.rio-mode-input--has_transition {
    --rio-mode-input--transition: var(--rio-mode-input--transition-speed) top, var(--rio-mode-input--transition-speed) width, var(--rio-mode-input--transition-speed) left, var(--rio-mode-input--transition-speed) height;
}

.rio-mode-input--has_visible-option-indicator {
    --rio-mode-input__option-indicator--opacity: 1;
    --rio-mode-input__moving-indicator--opacity: 0;
}

.rio-mode-input--no_wrap {
    --rio-mode-input--wrap: nowrap;
}

.rio-mode-input__option--is_interactive {
    --rio-mode-input__option--cursor: pointer;
}

/* :hover */
.rio-mode-input__option--is_interactive:hover:not(:active):not(.rio-mode-input__option--is_selected),
.rio-mode-input__option--is_interactive.rio-mode-input__option--is_soft-hover:not(:active) {
    --rio-mode-input__option--color: var(--color_text_interactive_hover);
    --rio-mode-input__option--background: var(--color_background_interactive_whisper_hover);
}

/* :active */
.rio-mode-input__option--is_interactive:not(.rio-mode-input__option--is_selected):active {
    --rio-mode-input__option--color: var(--color_text_interactive_active);
    --rio-mode-input__option--background: var(--color_background_interactive_whisper_active);
}

/* selected */
.rio-mode-input__option--is_selected {
    --rio-mode-input__option--color: var(--color_text_interactive_selected);
    --rio-mode-input__option--background: var(--color_background_neutral);
}

/* Variant: Error */
.rio-mode-input__option--is_error {
    --rio-mode-input__option--color: var(--color_text_danger);
}

.rio-mode-input__option--is_error.rio-mode-input__option--is_interactive:hover:not(:active):not(.rio-mode-input__option--is_selected) {
    --rio-mode-input__option--color: var(--color_text_danger_hover);
    --rio-mode-input__option--background: var(--color_background_danger_whisper_hover);
}

.rio-mode-input__option--is_error.rio-mode-input__option--is_interactive:active {
    --rio-mode-input__option--color: var(--color_text_danger_active);
    --rio-mode-input__option--background: var(--color_background_danger_whisper_active);
}

.rio-mode-input__option--is_error.rio-mode-input__option--is_selected {
    --rio-mode-input__option--color: var(--color_text_danger_selected);
}

/* Read Only: */
.rio-mode-input--is_read-only {
    --rio-mode-input__option--color: var(--color_text_readonly_quiet);
    --rio-mode-input__option--cursor: not-allowed;
}

.rio-mode-input__option--is_read-only.rio-mode-input__option--is_selected {
    --rio-mode-input__option--background: var(--color_background_readonly_loud);
    --rio-mode-input__option--color: var(--color_text_neutral_inverse);
}

/* Disabled: */
.rio-mode-input--is_disabled {
    --rio-mode-input--background: var(--color_background_interactive_disabled);
    --rio-mode-input__option--cursor: not-allowed;
}

.rio-mode-input__option--is_disabled {
    --rio-mode-input__option--color: var(--color_text_interactive_disabled);
    --rio-mode-input__option--cursor: not-allowed;
}

.rio-mode-input {
    position: relative;
    max-width: 100%;
    font-weight: 500;
    line-height: normal;
}

.rio-mode-input__label {
    font-size: 12px;
}

.rio-mode-input__options {
    display: flex;
    flex-flow: var(--rio-mode-input--wrap);
    row-gap: 4px;
    width: fit-content;
    max-width: 100%;
    border-radius: 1000px;
}

.rio-mode-input__option {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    white-space: nowrap;
    position: relative;
    padding: 0 8px;
    height: 24px;
    min-width: 32px;
    cursor: default;
    border-block-width: 1px;
    cursor: var(--rio-mode-input__option--cursor);
    color: var(--rio-mode-input__option--color);
    background: var(--rio-mode-input--background);
    border-color: var(--color_border_neutral_quiet);
}

.rio-mode-input__option:first-of-type {
    border-start-start-radius: 1000px;
    border-end-start-radius: 1000px;
    border-inline-start-width: 1px;
}

.rio-mode-input__option:last-of-type {
    border-start-end-radius: 1000px;
    border-end-end-radius: 1000px;
    border-inline-end-width: 1px;
}

.rio-mode-input__option:first-of-type:last-of-type {
    border-radius: 1000px;
    border-inline-width: 1px;
}

.rio-mode-input__option--no_compress {
    flex: 0 0 auto;
}

.rio-mode-input__option-label {
    z-index: 1;
}

.rio-mode-input__content {
    position: relative;
}

.rio-mode-input__content:not(:first-of-type) {
    margin-top: 8px;
}

.rio-mode-input__option-indicator {
    display: block;
    position: absolute;
    inset: 2px;
    border-radius: 1000px;
    background: var(--rio-mode-input__option--background);
    opacity: var(--rio-mode-input__option-indicator--opacity);
}

.rio-mode-input__moving-indicator {
    display: block;
    position: absolute;
    border-radius: 1000px;
    pointer-events: none;
    background: var(--color_background_neutral);
    transition: var(--rio-mode-input--transition);
    opacity: var(--rio-mode-input__moving-indicator--opacity);
}

.rio-mode-input__moving-indicator,
.rio-mode-input__option--is_interactive:not(.rio-mode-input__option--is_selected):hover .rio-mode-input__option-indicator,
.rio-mode-input__option--is_interactive.rio-mode-input__option--is_soft-hover .rio-mode-input__option-indicator,
.rio-mode-input__option--is_interactive:not(.rio-mode-input__option--is_selected):active .rio-mode-input__option-indicator,
.rio-mode-input__option--is_selected .rio-mode-input__option-indicator {
    box-shadow: var(--shadow_glow_small);
    box-shadow: 0 0 2px #00000040;
}