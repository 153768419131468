.assistant {
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    border-radius: 8px;
    overflow: hidden;
    opacity: 1;
    z-index: 61;
    background: var(--color_background_neutral);
    box-shadow: var(--shadow_medium);
}

.assistant.assistant--animate {
    transition: opacity 250ms, top 500ms, left 500ms, width 500ms, height 500ms;
}

.assistant__title {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    inset-block-start: 0;
    inset-inline-start: 0;
    height: 32px;
    width: 100%;
    color: var(--color_text_twilight_inverse);
    background: var(--color_background_twilight);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: var(--ANIMATION__duration__fast) background-color;
    user-select: none;
    cursor: move;
    padding-inline-end: 3px;
    font-weight: 500;
    font-size: 14px;
}

.assistant__title__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    font-size: 16px;
}

.assistant__title__title {
    margin-inline-end: auto;
}

.assistant__title__button__icon {
    pointer-events: none;
}

.assistant__content {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    width: 100%;
    overflow: hidden;
}

.assistant__content-children {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    width: 100%;
    overflow: auto;
    color: var(--color_text_neutral_loud);
}

.assistant__content-header,
.assistant__content-footer {
    position: relative;
    flex: 0 0 auto;
    width: 100%;
}

.assistant__header {
    position: relative;
    display: flex;
    flex: 1 1 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    width: 100%;
    min-height: 32px;
    padding: 0 4px;
    background: var(--color_background_neutral_quiet);
}

.assistant__header__checkbox.g-override {
    margin-top: 4px;
}

.assistant__header__title {
    overflow: hidden;
    font-weight: 500;
    font-size: 13px;
}

.assistant__header__title:first-child {
    padding-inline-start: 12px;
}

.assistant__footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 32px;
    width: 100%;
    padding: 4px 8px 8px 8px;
    background: var(--color_background_neutral_quiet);
}

.assistant__footer__children {
    display: flex;
    flex: 1 0 0;
    align-items: center;
    justify-content: center;
}

.assistant__index {
    position: relative;
}

.assistant__index__option {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    user-select: none;
    min-height: 54px;
    padding-inline: 20px;
    gap: 12px;
}

.assistant__index__option__icon {
    position: relative;
    display: block;
    flex: 0 0 auto;
    font-size: 16px;
    color: var(--color_icon_neutral);
}

.assistant__index__option__content {
    width: 100%;
}

.assistant__index__option--clickable {
    cursor: pointer;
}

.assistant__index__option--clickable,
.assistant__index__option--clickable .assistant__index__option__description,
.assistant__index__option--clickable .assistant__index__option__label {
    transition: var(--ANIMATION__duration__fast) background-color, var(--ANIMATION__duration__fast) color;
}

/* :hover */
.assistant__index__option--clickable:hover:not(:active) {
    background: var(--color_background_interactive_whisper_hover);
}

/* :active */
.assistant__index__option--clickable:active {
    background: var(--color_background_interactive_whisper_active);
}

/* disabled */
.assistant__index__option:not(.assistant__index__option--clickable) {
    background: var(--color_background_interactive_disabled);
    cursor: not-allowed;
}

.assistant__index__option:not(.assistant__index__option--clickable) .assistant__index__option__icon {
    color: var(--color_icon_interactive_disabled);
}

.assistant__index__option:not(.assistant__index__option--clickable) .assistant__index__option__content * {
    color: var(--color_text_interactive_disabled);
}

.assistant__list__group-container:not(:first-child) {
    border-top: 1px solid var(--color_border_neutral_whisper);
}

.assistant__list__group-container:last-child {
    border-bottom: 1px solid var(--color_border_neutral_whisper);
}

.assistant__list__group__button-container {
    position: sticky;
    inset-block-start: 0;
    z-index: 2;
}

.assistant__list__group-button {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 36px;
    line-height: 36px;
    padding: 0;
    padding-inline-end: 16px;
    user-select: none;
    text-align: start;
    cursor: pointer;
    z-index: 60;
    background: var(--color_background_neutral);
    transition: background var(--ANIMATION__duration__fast);
    font-weight: 500;
    font-size: 13px;
}

.assistant__list__group__icon {
    position: relative;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 24px;
    color: var(--color_icon_neutral_whisper);
    transition: color var(--ANIMATION__duration__fast);
}

.assistant__list__group__icon:not(.assistant__list__group__icon--expander) {
    width: auto;
    padding-inline-end: 8px;
}

.assistant__list__group__icon__expander {
    transition: transform var(--ANIMATION__duration__fast);
}

.assistant__list__group__icon__expander--open {
    transform: rotate(90deg);
}

[dir="rtl"] .assistant__list__group__icon__expander--open {
    transform: rotate(-90deg);
}

.assistant__list__group__label {
    position: relative;
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    height: 24px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.assistant__list__group__count {
    position: relative;
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    height: 24px;
    line-height: 24px;
}

.assistant__list__group-button:hover {
    background: var(--color_background_neutral_hover);
}

.assistant__list__group-button:hover .assistant__list__group__icon {
    color: var(--color_text_neutral_quiet);
    transition: transform var(--ANIMATION__duration__fast);
}

.assistant__list__group-button:active {
    background: var(--color_background_neutral_active);
}

.assistant__list__group-button:active .assistant__list__group__icon {
    color: var(--color_icon_interactive_loud_active);
    transition: transform var(--ANIMATION__duration__fast);
}

.assistant__list__group_children {
    position: relative;
    overflow: hidden;
    max-height: none;
    opacity: 1;
    transition: opacity var(--ANIMATION__duration__fast);
}

.assistant__list__group_children--hidden {
    max-height: 0;
    opacity: 0;
}

.assistant__list__option-checkbox {
    position: absolute;
    display: block;
    margin-block-start: 2px;
    margin-inline-start: 4px;
    z-index: 1;
}

.assistant__list__option {
    position: relative;
    display: block;
    width: 100%;
    padding: 8px 16px;
    background: transparent;
    text-align: start;
    user-select: none;
}

.assistant__list__option--checkbox {
    padding-inline-start: 36px;
}

.assistant__list__option--icon {
    padding-inline-start: 44px;
}

.assistant__list__option--checkbox.assistant__list__option--icon {
    padding-inline-start: 68px;
}

.assistant__list__option--openable {
    padding-inline-end: 32px;
}

.assistant__list__option__icon {
    position: absolute;
    inset-block-start: 8px;
    inset-inline-start: 16px;
    width: 20px;
    font-size: 16px;
    color: var(--color_text_neutral_quiet);
}

.assistant__list__option--checkbox .assistant__list__option__icon {
    inset-inline-start: 40px;
}

.assistant__list__option__opener {
    position: absolute;
    display: flex;
    align-items: center;
    justify-items: center;
    inset-block-start: 8px;
    inset-inline-end: 8px;
    width: 20px;
    height: calc(100% - 16px);
    color: var(--color_text_neutral_quiet);
}

.assistant__list__option__opener__icon {
    pointer-events: none;
}

.assistant__list__option__label {
    font-weight: 500;
    font-size: 13px;
    display: block;
    line-height: 18px;
    color: var(--color_text_neutral_loud);
}

.assistant__list__option__description {
    font-weight: 500;
    font-size: 10px;
    display: block;
    line-height: 16px;
    color: var(--color_text_neutral_quiet);
}

.assistant__list__option__description > span:first-child:not(:last-child)::after {
    content: "|";
    margin: 0 8px;
}

.assistant__list__option--clickable {
    cursor: pointer;
}

.assistant__list__option--clickable,
.assistant__list__option--clickable .assistant__list__option__icon,
.assistant__list__option--clickable .assistant__list__option__description,
.assistant__list__option--clickable .assistant__list__option__label,
.assistant__list__option--clickable .assistant__list__option__opener {
    transition: var(--ANIMATION__duration__fast) background-color, var(--ANIMATION__duration__fast) color;
}

.assistant__list__option--clickable:hover {
    background: var(--color_background_interactive_whisper_hover);
}

.assistant__list__option--clickable:hover .assistant__list__option__description,
.assistant__list__option--clickable:hover .assistant__list__option__label {
    color: var(--color_text_interactive_hover);
}

.assistant__list__option--clickable:hover .assistant__list__option__icon,
.assistant__list__option--clickable:hover .assistant__list__option__opener {
    color: var(--color_icon_interactive_hover);
}

.assistant__list__option--clickable:active {
    background: var(--color_background_interactive_whisper_active);
}

.assistant__list__option--clickable:active .assistant__list__option__description,
.assistant__list__option--clickable:active .assistant__list__option__label {
    color: var(--color_text_interactive_active);
}

.assistant__list__option--clickable:active .assistant__list__option__icon,
.assistant__list__option--clickable:active .assistant__list__option__opener {
    color: var(--color_icon_interactive_active);
}

.assistant__list__option--active,
.assistant__list__option--active.assistant__list__option--clickable {
    background: var(--color_background_interactive_whisper_selected);
}

.assistant__list__option--active .assistant__list__option__description,
.assistant__list__option--active.assistant__list__option--clickable .assistant__list__option__description,
.assistant__list__option--active .assistant__list__option__label,
.assistant__list__option--active.assistant__list__option--clickable .assistant__list__option__label {
    color: var(--color_text_interactive_selected);
}

.assistant__list__option--active .assistant__list__option__icon,
.assistant__list__option--active.assistant__list__option--clickable .assistant__list__option__icon,
.assistant__list__option--active .assistant__list__option__opener,
.assistant__list__option--active.assistant__list__option--clickable .assistant__list__option__opener {
    color: var(--color_icon_interactive_selected);
}

.assistant__resize-handle {
    position: absolute;
    bottom: 0px;
    inset-inline-start: 0;
    width: 100%;
    height: 5px;
    background: transparent;
    overflow: hidden;
    transition: var(--ANIMATION__duration__fast) background-color;
    cursor: ns-resize;
}

.assistant__resize-handle:hover {
    background: var(--color_background_interactive_whisper_hover);
}

.assistant__resize-handle:active {
    background: var(--color_background_interactive_whisper_active);
}